<template>
  <v-card v-show="hasJobRunning">
    <v-card-title>
      <span v-html="$t('job_progress_header')" />
    </v-card-title>
    <v-card-text>
      <div>
        <span v-html="$t('job_progress_info')" />
        <v-progress-linear :value="(100 * this.job.totalDone) / this.job.totalItems" color="cyan" height="10" striped></v-progress-linear>
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'job-progress',
  data() {
    return {
      hasJobRunning: false,
      timer: null,
      dialog: false,
      requestPending: false,
      totalItems: Number,
      totalDone: Number,
      timerCnt: 0,
      job: {
        totalDone: 0,
        totalItems: 1
      }
    }
  },
  props: {
    jobType: { type: String },
    path: { type: String },
    // first n-times progress bar is updated in fast intervals, afterward in slow intervals
    numberOfFastIntervals: { default: 30, type: Number, required: false },
    fastInterval: { default: 100, type: Number, required: false }, // ms
    slowInterval: { default: 3000, type: Number, required: false } // ms
  },
  methods: {
    run() {
      // no timer running, set fast timer
      if (!this.timer) {
        this.setFastTimer()
      }
    },
    async findRunningJob() {
      this.timerCnt++

      // fast timer running more than numberOfFastIntervals, set slow timer
      if (this.timerCnt == this.numberOfFastIntervals + 1) {
        this.setSlowTimer()
      }

      // sometimes fasttimer are faster than the backend request
      if (this.requestPending) return
      // get job status
      try {
        this.requestPending = true
        const response = await this.axios.get(apiURL + `/jobs/find`, {
          params: {
            ...{ path: this.path },
            ...{ jobType: this.jobType }
          }
        })
        // running job found
        this.hasJobRunning = true
        this.$emit('hasJobRunning', this.hasJobRunning)
        this.job = response.data
      } catch (e) {
        if (e.response.status === 404) {
          clearInterval(this.timer) // clearInterval clears the timer, but not the variable on this.interval
          this.resetTimer()
          this.timer = null
          this.$emit('hasJobRunning', this.hasJobRunning)
          this.timerCnt = 0
        } else {
          console.log(e)
          this.run() // inhibits constant fast timer when error occurs
        }
      } finally {
        this.requestPending = false
      }
    },
    resetTimer() {
      this.timer = null
      this.timerCnt = 0
      this.hasJobRunning = false
      this.interval = this.fastInterval
    },
    setSlowTimer() {
      clearInterval(this.timer)
      this.timer = null
      this.timer = setInterval(this.findRunningJob, this.slowInterval)
    },
    setFastTimer() {
      this.timer = setInterval(this.findRunningJob, this.fastInterval)
    }
  },
  computed: {},
  mounted() {
    this.run()
  }
})
</script>
