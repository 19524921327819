<template>
  <v-dialog v-model="showDialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span v-html="this.$t(`milk_quality_content_dialog_${this.orderType}_header`)" />
      </v-card-title>
      <v-card-text>
        <h2 v-html="createSubTitle" />
        <div class="inline-dates">
          <DbmMonthPicker
            name="orderDate"
            id="orderDate"
            v-model="orderDate"
            :rules="calculateRules()"
            v-show="this.orderType === 'order'"
            :dateLabel="this.$t(`milk_quality_content_dialog_${this.orderType}_date`)"
            :clearable="true"
            returnType="firstOfMonth"
          />
          <DbmMonthPicker
            name="cancelDate"
            id="cancelDate"
            v-model="cancelDate"
            :dateLabel="this.$t(`milk_quality_content_dialog_cancel_date`)"
            :rules="calculateRules()"
            :clearable="true"
            returnType="lastOfMonth"
          />
        </div>
        <div v-html="createCancelMessage" v-if="orderType === 'cancel'"></div>
        <v-checkbox v-model="termsAccepted" name="termsAccepted" :label="$t('milk_quality_content_dialog_conditions')"></v-checkbox>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          @click="saveDialog()"
          :disabled="
            !termsAccepted ||
            (this.orderDate == null && this.orderType === 'order') ||
            (this.cancelDate == null && this.orderType === 'cancel') ||
            (this.cancelDate != null && this.orderDate != null && this.orderDate > this.cancelDate)
          "
        >
          <span v-html="this.$t(`milk_quality_content_dialog_${this.orderType}_save_close`)" />
        </v-btn>
        <v-btn color="secondary" class="shadow-none" @click="closeDialog()" v-html="$t('cancel')"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { concat } from 'lodash'
import { privileges } from '@/services/privileges'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'dialogMilkQualityContent',
  components: { DbmMonthPicker },
  props: {},
  data() {
    return {
      showDialog: false,
      orderDate: null,
      cancelDate: null,
      orderType: '',
      orderCount: 0,
      dairyId: 0,
      termsAccepted: false
    }
  },
  emits: ['saveDialog'],
  computed: {
    createSubTitle() {
      const formatter = new CustomFormatter()
      return formatter.interpolate(this.$t(`milk_quality_content_dialog_${this.orderType}_header2`), [this.orderCount, this.dairyIdent])
    },
    createCancelMessage() {
      if (this.cancelDate == null) {
        return ''
      }
      const formatter = new CustomFormatter()
      return formatter.interpolate(this.$t(`milk_quality_content_dialog_cancel_message`), [
        concat(this.cancelDate.slice(5, 7), this.cancelDate.slice(0, 4)).join('.')
      ])
    },
    /**
     * Check if all dairyIds are the same
     * Otherwise the order/cancel button should be disabled
     * @returns {boolean}
     */
    dairyIdIsUnique() {
      if (this.item.length == 0) return false
      const setDairyIdsArray = new Set(this.item.map((item) => item.dairyId))
      return setDairyIdsArray.size <= 1
    }
  },
  methods: {
    calculateRules() {
      if (this.orderType === 'cancel') {
        if (privileges.has({ path: '/milkQualities', permission: 'allDomains' })) {
          return []
        } else {
          return [this.$rules.isMaxTwoMonthsPast]
        }
      } else {
        if (privileges.has({ path: '/milkQualities', permission: 'allDomains' })) {
          return [this.$rules.isUntilGreaterThanFrom(this.orderDate, this.cancelDate)]
        } else {
          return [this.$rules.isUntilGreaterThanFrom(this.orderDate, this.cancelDate), this.$rules.isMaxTwoMonthsPast]
        }
      }
    },
    closeDialog() {
      this.showDialog = false
    },
    saveDialog() {
      this.$emit('saveDialog', this.orderDate, this.cancelDate, this.orderType, this.dairyId)
      this.showDialog = false
    },
    openDialog(orderType, count, dairyIdent, dairyId) {
      this.showDialog = true
      this.orderCount = count
      this.orderType = orderType
      this.dairyIdent = dairyIdent
      this.dairyId = dairyId
    }
  }
})
</script>
