<template>
  <v-container>
    <v-form ref="contentForm" @submit.prevent="">
      <v-row class="row search spacer-md">
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-farm ref="search-farm" @search="search" />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-contact ref="search-contact" @search="search" />
        </v-col>
        <!--
        todo: repair contactverwaltung 
        <v-col cols="12" sm="6" md="4" lg="2" >
          <search-contact-attribute-and-relation
            ref="search-contactattributeandrelation"
            @search="search"
            :showPicker="true"
            storeName="SearchContactAttributesRelationsStore"
          />
        </v-col>
        -->
        <v-col cols="12" sm="6" md="4" lg="2">
          <searchMilkQualityContent ref="search-milkqualitycontent" @search="search" />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="2">
          <searchDairy ref="search-dairy" @search="search" />
        </v-col>
      </v-row>
      <v-btn color="primary" @click="search" v-html="$t('milkquality_content_search')"></v-btn>
      <v-btn color="secondary" @click="reset" v-html="$t('milkquality_content_reset')"></v-btn>
    </v-form>
    <v-menu offset-y :disabled="!isReadyToOrder">
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="formular" color="secondary" v-bind="props">
          <span v-html="$t('milkquality_content_menu_order')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="orderSearched" v-show="!singleSelection" :disabled="!hasItems" id="milkquality_content_menu_order_searched">
          <v-list-item-title v-html="$t('milkquality_content_menu_order_searched')"></v-list-item-title>
        </v-list-item>
        <v-list-item @click="orderMarked" v-show="singleSelection" :disabled="!hasItemsChecked" id="milkquality_content_menu_order_marked">
          <v-list-item-title v-html="$t('milkquality_content_menu_order_marked')"></v-list-item-title>
        </v-list-item>
        <v-list-item @click="cancelSearched" v-show="!singleSelection" :disabled="!hasItems" id="milkquality_content_menu_cancel_searched">
          <v-list-item-title v-html="$t('milkquality_content_menu_cancel_searched')"></v-list-item-title>
        </v-list-item>
        <v-list-item @click="cancelMarked" v-show="singleSelection" :disabled="!hasItemsChecked" id="milkquality_content_menu_cancel_marked">
          <v-list-item-title v-html="$t('milkquality_content_menu_cancel_marked')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn color="secondary" :loading="downloading" @click="download" v-html="$t('download')" :disabled="hasJobRunning"></v-btn>
    <div v-show="dairyIdIsUnique && !hasJobRunning" v-html="$t('milkqualities_content_info')"></div>
    <div v-show="singleSelection && totalElements > selectionSingleMax" v-html="$t('milkqualities_content_info_more_than_thousand')"></div>
    <div v-show="!dairyIdIsUnique" v-html="$t('milkqualities_content_info_multiple_dairies')"></div>

    <job-progress
      ref="jobProgress"
      jobType="MILK_QUALITY_CONTENT"
      path="milkQualities/content"
      @hasJobRunning="setJobRunning"
      :numberOfFastIntervals="5"
      :fast-interval="500"
      :slow-interval="5000"
    />
    <v-checkbox
      id="checkbox-1"
      v-model="singleSelection"
      :label="$t('milkquality_content_btn_ingleselection')"
      class="position-left"
      :disabled="!dairyIdIsUnique || hasJobRunning"
    ></v-checkbox>

    <v-data-table
      :multi-sort="true"
      :show-select="true"
      select-strategy="single"
      v-model="selected"
      :single-select="false"
      item-value="uniqueKey"
      :items="items"
      :headers="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      :hide-default-footer="singleSelection"
      dense
    >
      <template v-slot:[`item.agisId`]="{ item }">
        <template v-if="$privileges.has({ path: '/farms', permission: 'write' })">
          <router-link :to="{ name: 'masterdata_farms_edit', params: { id: item.farmId, tab: 'overview' } }">
            {{ item.agisId ? item.agisId : $t('none') }}
          </router-link>
        </template>
        <template v-else>
          {{ item.agisId }}
        </template>
      </template>
      <template v-slot:[`item.dairyIdent`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
          <router-link :to="{ name: 'masterdata_dairies_edit', params: { id: item.dairyId, tab: 'overview' } }">
            {{ item.dairyIdent }}
          </router-link>
        </template>
        <template v-else>
          <td>
            {{ item.dairyIdent }}
          </td>
        </template>
      </template>
      <template v-slot:[`item.contentValidFrom`]="{ item }">
        {{ $formatChDate(item.contentValidFrom) }}
      </template>
      <template v-slot:[`item.contentValidUntil`]="{ item }">
        {{ $formatChDate(item.contentValidUntil) }}
      </template>
    </v-data-table>

    <dialogMilkQualityContent ref="dialogMilkQualityContent" @saveDialog="saveDialog" />
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { Term } from '@/services/term'

import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'

import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchContactAttributeAndRelation from '@/components/searchCards/searchContactAttributeAndRelation.vue'

import searchMilkQualityContent from '@/components/searchCards/searchMilkQualityContent.vue'
import { fileCreator, showError } from '@/services/axios'
import { personsService } from '@/services/persons'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import dialogMilkQualityContent from '@/views/milkQualities/content/dialogMilkQualityContent.vue'
import JobProgress from '@/components/jobProgress.vue'
import _ from 'lodash'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'milkqualities_content_search',
  data() {
    return {
      orderDate: this.$moment().add(1, 'M').startOf('month'),
      cancelDate: this.$moment().add(1, 'M').endOf('month'),
      contentDate: this.$moment().add(1, 'M').endOf('month'),
      animalTypeId: 1,
      dialog: false,
      orderType: '',
      items: [],
      initialized: false,
      loading: false,
      downloading: false,
      selected: [],
      hasJobRunning: false,
      singleSelection: this.$route.query.singleSelection === 'true' ? true : false,
      selectionSingleMax: 1000,
      selectionDefault: 50,
      timer: null,
      fields: [
        {
          title: this.$t('milkquality_content_table_header_producer_number'),
          value: 'producerNumber',
          sortable: false,
          width: '2%'
        },
        // AGID-ID Betrieb
        {
          title: this.$t('milkquality_content_table_header_agisid'),
          value: 'agisId',
          align: 'left',
          sortable: true
        },
        // Betriebsart Betrieb
        {
          title: this.$t('milkquality_content_table_header_local_unit_type'),
          value: `type${this.$route.params.upLangKey}`,
          align: 'left',
          sortable: true
        },
        // Name / Betrieb
        {
          title: this.$t('milkquality_content_table_header_name_company'),
          value: 'name1',
          sortable: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('milkquality_content_table_header_name_addition'),
          value: 'name2',
          sortable: true
        },
        // PLZ
        {
          title: this.$t('milkquality_content_table_header_zip'),
          value: 'zip',
          sortable: true,
          align: 'left'
        },
        // Ort
        {
          title: this.$t('milkquality_content_table_header_localty'),
          value: 'locality',
          sortable: true
        },
        {
          title: this.$t('milkquality_content_table_dairy_ident'),
          value: 'dairyIdent',
          sortable: true
        }, // Name / Betrieb
        {
          title: this.$t('milkquality_content_table_dairy_name1'),
          value: 'dairyName1',
          sortable: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('milkquality_content_table_dairy_name2'),
          value: 'dairyName2',
          sortable: true
        },

        {
          title: this.$t('milkquality_content_table_content_valid_from'),
          value: 'contentValidFrom',
          sortable: true
        },
        {
          title: this.$t('milkquality_content_table_content_valid_until'),
          value: 'contentValidUntil',
          sortable: true
        }
      ],
      totalElements: 0
    }
  },
  computed: {
    hasItems() {
      return this.items.length > 0
    },
    hasItemsChecked() {
      return this.selected.length > 0
    },
    isReadyToOrder() {
      return this.hasItems && this.dairyIdIsUnique && !this.hasJobRunning
    },
    SearchMilkQualityContentStore() {
      return this.$store.state.SearchMilkQualityContentStore
    },
    term() {
      return [
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)
      ]
    },
    params() {
      return {
        animalTypeId: this.$store.state.SearchMilkQualityContentStore.animalTypeId,
        ordered: this.$store.state.SearchMilkQualityContentStore.ordered,
        contentDate: this.$store.state.SearchMilkQualityContentStore.contentDate,
        producerNumber: this.$store.state.SearchMilkQualityContentStore.producerNumber
      }
    },
    query() {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions({ sort: ['localUnitStatus,asc', 'legalEntityName1,asc'] }),
        ...{ singleSelection: this.singleSelection }
      }
    },
    dairyIdIsUnique() {
      if (this.items.length == 0) return false
      const dairyIds = _.chain(this.items).map(function (x) {
        return x.dairyId
      })
      const dairyIdsUniqueArray = Array.from(new Set(dairyIds))
      return dairyIdsUniqueArray.length <= 1
    }
  },
  components: {
    JobProgress,
    DbmMonthPicker,
    searchFarm,
    searchContact,
    searchDairy,
    searchContactAttributeAndRelation,
    searchMilkQualityContent,
    dialogMilkQualityContent
  },
  methods: {
    async search() {
      if (!(await this.$refs.contentForm.validate()).valid) return true
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    setJobRunning(componentHasJobRunning) {
      if (!componentHasJobRunning) {
        if (this.hasJobRunning === true) this.load()
      } else {
        this.dialog = false
        this.$refs.dialogMilkQualityContent.closeDialog()
      }
      this.hasJobRunning = componentHasJobRunning
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        this.selected = []
        const response = await this.axios.get(apiURL + '/milkQualities/content/search', {
          params: this.query
        })
        const items = response.data.content
        items.map((itemX) => (itemX.uniqueKey = `${itemX.farmId}_${itemX.dairyIdent}_${itemX.dairyId}_${itemX.contentValidFrom}_${itemX.contentValidUntil}`))
        this.items = Array.isArray(items) ? items : []

        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-farm'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-dairy'].reset()
      this.$refs['search-milkqualitycontent'].reset()
      this.$refs['search-contactattributeandrelation'].reset()
      this.$refs['search-milkqualitycontent'].reset()
    },
    async download() {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + '/milkQualities/content', {
          params: this.query,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        // if loading takes too long
        if ((await response.status) === 204) {
          emitter.emit('openDownloadInfoDialog')
        } else {
          fileCreator(await response, 'gehaltsbestellung.xlsx')
        }
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    async userSwitch(item) {
      await personsService.impersonateAsUser(item)
    },
    // a datatable uniquekey ist build like: `${itemX.farmId}_${itemX.dairyId}_${itemX.contentValidFrom}_${itemX.contentValidUntil}`
    uniqueKeyToObj(uk: string) {
      let parts = uk.split('_')
      return { farmId: parts[0], dairyIdent: parts[1], dairyId: parts[2], contentValidFrom: parts[3], contentValidUntil: parts[4] }
    },
    orderSearched() {
      this.$refs.jobProgress.run() // closes dialog if job is running
      this.$refs.dialogMilkQualityContent.openDialog('order', this.items.length, this.items[0].dairyIdent, this.items[0].dairyId)
    },
    orderMarked() {
      this.$refs.jobProgress.run() // closes dialog if job is running
      let item = this.uniqueKeyToObj(this.selected[0])
      this.$refs.dialogMilkQualityContent.openDialog('order', this.selected.length, item.dairyIdent, item.dairyId)
    },
    cancelSearched() {
      this.$refs.jobProgress.run() // closes dialog if job is running
      this.$refs.dialogMilkQualityContent.openDialog('cancel', this.items.length, this.items[0].dairyIdent, this.items[0].dairyId)
    },
    cancelMarked() {
      this.$refs.jobProgress.run() // closes dialog if job is running
      let item = this.uniqueKeyToObj(this.selected[0])
      this.$refs.dialogMilkQualityContent.openDialog('cancel', this.selected.length, item.dairyIdent, item.dairyId)
    },
    async saveDialog(orderDate, cancelDate, orderType, dairyId) {
      if (orderType === 'cancel') {
        // Set orderDate to first of month of cancelDate.
        // This checks if a content order is active.
        orderDate = `${cancelDate.slice(0, 4)}-${cancelDate.slice(5, 7)}-01`
      }
      try {
        if (this.singleSelection) {
          await this.axios.post(
            `${apiURL}/milkQualities/content/${orderType}`,
            {
              dairyId: dairyId,
              farmIds: this.selected.map((item) => this.uniqueKeyToObj(item).farmId),
              contentDate: this.$store.state.SearchMilkQualityContentStore.contentDate,
              validFrom: orderDate,
              validUntil: cancelDate
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
        } else {
          this.items = []
          await this.axios.get(apiURL + `/milkQualities/content/${orderType}WithParam`, {
            params: {
              ...this.query,
              ...{ validFrom: orderDate },
              ...{ validUntil: cancelDate }
            }
          })
        }
        this.hasJobRunning = true
        this.$refs.jobProgress.run()
      } catch (e) {
        showError(e)
      }
    }
  },
  watch: {
    singleSelection: {
      handler(value) {
        // persist singleselection and dependent size to the url
        this.$router
          .push({
            path: this.$route.path,
            query: {
              ...this.query,
              ...{ singleSelection: value, size: value === true ? this.selectionSingleMax : this.selectionDefault }
            }
          })
          .catch((e) => {
            // route duplicated
            this.load()
          })
      }
    },
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.initialized = true
    if (this.$route.query.size) this.load()
  },
  onBeforeUnmount() {
    this.$refs.jobProgress.resetTimer()
  }
})
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.aligned-buttons {
  display: flex !important;
  float: right;
}

.button-margin-left {
  margin-left: $spacer-xs !important;
}
</style>
